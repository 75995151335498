import React, { Suspense, lazy, useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import { ConfigProvider } from "antd";
import LoginForm from "./screens/auth/LoginForm";
import AuthContext from "./contexts/AuthContext";
import theme from "./theming/token";
import { colors } from "./theming/color";
import { FullLoadingComponent } from "./components/LoadingComponent";
import Cookies from "js-cookie";
import locale from "antd/locale/es_ES";
import dayjs from "dayjs";
import "dayjs/locale/es-us";
import { getUserProfile } from "./utils/UserProfile";
import CacheBuster from "react-cache-buster";
import packageInfo from "../package.json";

const Dashboard = lazy(() => import("./screens/dashboard/Dashboard"));

dayjs.locale("es-us");

const { token } = colors;

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isCheckingToken, setIsCheckingToken] = useState(true);

  const login = () => {
    setIsAuthenticated(true);
  };

  const logout = () => {
    setIsAuthenticated(false);
  };

  useEffect(() => {
    const token = Cookies.get("token");
    if (token) {
      login();
      const fetchUserProfile = async () => {
        const profile = await getUserProfile();
        if (!profile) {
          logout();
          return;
        }
        localStorage.setItem(
          "profilePicture",
          `${process.env.REACT_APP_BASE_URL}${profile?.img_perfil}`
        );
        localStorage.setItem("username", profile?.username);
        localStorage.setItem("role", profile?.role);
      };
      fetchUserProfile();
    }
    setIsCheckingToken(false);
  }, []);

  if (isCheckingToken) {
    return <FullLoadingComponent />;
  }

  const version = packageInfo.version;
  return (
    <CacheBuster
      currentVersion={version}
      isEnabled={true} //If false, the library is disabled.
      isVerboseMode={false} //If true, the library writes verbose logs to console.
      loadingComponent={<FullLoadingComponent />} //If not pass, nothing appears at the time of new version check.
      metaFileDirectory={"."} //If public assets are hosted somewhere other than root on your server.
    >
      <ConfigProvider theme={theme} locale={locale}>
        <div className="Dragon Translation Staff" style={{ background: token.colorBgBase }}>
          <AuthContext.Provider value={{ isAuthenticated, login, logout }}>
            <Router>
              <Suspense fallback={<FullLoadingComponent />}>
                <Routes>
                  <Route path="/login" element={<LoginForm />} />
                  <Route
                    path="/*"
                    element={
                      isAuthenticated ? <Dashboard /> : <Navigate to="/login" />
                    }
                  />
                </Routes>
              </Suspense>
            </Router>
          </AuthContext.Provider>
        </div>
      </ConfigProvider>
    </CacheBuster>
  );
}

export default App;
