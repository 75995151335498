import Cookies from "js-cookie";
import {
  Button,
  Checkbox,
  Form,
  Input,
  Avatar,
  Typography,
  notification,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/AuthContext";
import { useContext, Suspense, useState } from "react";
import styled from "styled-components";
import {
  FullLoadingComponent,
} from "../../components/LoadingComponent";
import axiosInstance from "../../utils/AxiosInstance";

const { Title } = Typography;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const Box = styled.div`
  width: 100%;
  max-width: 300px;
  padding: 50px;
  background-color: #23272a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;

  @media (max-width: 768px) {
    padding: 20px;
  }
`;

const StyledForm = styled(Form)`
  width: 100%;
`;

const messages = {
  usernameRequired: "Por favor, ingrese su Nombre de Usuario",
  passwordRequired: "Por favor, ingrese su Contraseña",
};

const onFinishFailed: any = (errorInfo: any) => {
};

const src = "/imgs/img_default_profile.png";

const UsernameInput = () => (
  <Form.Item
    name="username"
    rules={[{ required: true, message: messages.usernameRequired }]}
  >
    <Input
      prefix={<UserOutlined className="site-form-item-icon" />}
      placeholder="Username"
    />
  </Form.Item>
);

const PasswordInput = () => (
  <Form.Item
    name="password"
    rules={[{ required: true, message: messages.passwordRequired }]}
  >
    <Input
      prefix={<LockOutlined className="site-form-item-icon" />}
      type="password"
      placeholder="Password"
    />
  </Form.Item>
);

const LoginForm = () => {
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const [loading, setLoading] = useState(false); // Nuevo estado para el control de carga

  const onFinish = async (values: any) => {
    setLoading(true); // Establecer loading en true cuando se inicia la petición

    const form = new FormData();
    form.append("username", values.username);
    form.append("password", values.password);

    const options = {
      method: "POST",
      url: "/api/login",
      headers: {
        "content-type":
          "multipart/form-data; boundary=---011000010111000001101001",
      },
      data: form,
    };

    try {
      const { data } = await axiosInstance(options);

      // Almacenar el token en una cookie
      Cookies.set("token", data.token);
      localStorage.setItem(
        "profilePicture",
        `${process.env.REACT_APP_BASE_URL}${data.img_perfil}`
      );
      // Almacenar el rol y el id del usuario en el localStorage
      localStorage.setItem("role", data.role);
      localStorage.setItem("username", data.username);
      if (authContext) {
        authContext.login();
        navigate("/dashboard");
      }
    } catch (error) {
      if ((error as any).response && (error as any).response.status === 422) {
        notification.error({
          message: "Error",
          description: (error as any).response.data.message,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Suspense fallback={<FullLoadingComponent />}>
      <Container>
        <Box>
          <Avatar size={200} src={src} />
          <Title level={2}>Bienvenid@</Title>
          <StyledForm
            name="normal_login"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
          >
            <UsernameInput />
            <PasswordInput />
            <Form.Item>
              <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox>Recuérdame</Checkbox>
              </Form.Item>
            </Form.Item>

            <Form.Item style={{ display: "flex", justifyContent: "center" }}>
              <Button
                type="primary"
                htmlType="submit"
                className="login-form-button"
                loading={loading}
              >
                Iniciar Sesión
              </Button>
            </Form.Item>
          </StyledForm>
        </Box>
      </Container>
    </Suspense>
  );
};
export default LoginForm;
