import React from "react";

interface AuthContextInterface {
  isAuthenticated: boolean;
  login: () => void;
  logout: () => void;
}

const AuthContext = React.createContext<AuthContextInterface | null>(null);

export default AuthContext;
