import Cookies from "js-cookie";
import axiosInstance from "./AxiosInstance";

const getUserProfile = async () => {
  const token = Cookies.get("token");

  try {
    const response = await axiosInstance.get("/api/getUserProfile", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
  }

  return null;
};

export { getUserProfile };
