export type ColorsType = {
  token: {
    colorPrimary: string;
    colorInfo: string;
    borderRadius: number;
    colorTextBase: string;
    colorBgBase: string;
    colorBorder: string;
    colorBorderSecondary: string;
    colorErrorBg: string;
    colorWarningBg: string;
    colorSuccessBg: string;
    colorPrimaryBg: string;
  };
  components: {
    Layout: {
      triggerBg: string;
    },
    Button: {
      colorLink: string;
    },
    Tooltip: {
      colorTextLightSolid: string;
      colorBgSpotlight: string;
    };
  };
};

export const colors: ColorsType = {
  token: {
    colorPrimary: "#d9183b",
    colorInfo: "#d9183b",
    borderRadius: 8,
    colorTextBase: "#ffffff",
    colorBgBase: "#121212",
    colorBorder: "#434343",
    colorBorderSecondary: "#434343",
    colorErrorBg: "#23272a",
    colorWarningBg: "#23272a",
    colorSuccessBg: "#23272a",
    colorPrimaryBg: "#23272a",
  },
  components: {
    Layout: {
      triggerBg: "rgb(28, 28, 28)",
    },
    Button: {
      colorLink: "rgb(255, 255, 255)",
    },
    Tooltip: {
      colorTextLightSolid: "rgb(206, 203, 203)",
      colorBgSpotlight: "rgba(56, 56, 56, 0.85)",
    },
  },
};
