import type { ThemeConfig } from "antd";
import { colors } from "./color";

const { token } = colors;
const theme: ThemeConfig = {
  token: {
    colorPrimary: token.colorPrimary,
    colorInfo: token.colorInfo,
    borderRadius: token.borderRadius,
    colorTextBase: token.colorTextBase,
    colorBgBase: token.colorBgBase,
    colorBorder: token.colorBorder,
    colorBorderSecondary: token.colorBorderSecondary,
    colorErrorBg: token.colorErrorBg,
    colorWarningBg: token.colorWarningBg,
    colorSuccessBg: token.colorSuccessBg,
    colorPrimaryBg: token.colorPrimaryBg,
  },
  components: {
    Layout: {
      triggerBg: colors.components.Layout.triggerBg,
    },
    Button: {
      colorLink: colors.components.Button.colorLink,
    },
    Tooltip: {
      colorTextLightSolid: colors.components.Tooltip.colorTextLightSolid,
      colorBgSpotlight: colors.components.Tooltip.colorBgSpotlight,
    },
  },
};

export default theme;
